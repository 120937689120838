import React, { useState, useEffect } from 'react'
import ClientshotMockup from '../assets/images/clientshot+mockup.png'
import ClientshotLogo from '../assets/svgs/logo/Clientshot_logo.svg'
import ihvn from '../assets/images/clients/ihvn.svg'
import valuejet from '../assets/images/clients/valuejet.svg'
import ClientshotHero from '../assets/images/projects/clientshot/clientshot-hero.svg'
import ServiceCard from '../components/ServiceCard'
import ClientshotMockup02 from '../assets/images/projects/clientshot/clientshot-mockup02.svg'
import ClientshotMockup03 from '../assets/images/projects/clientshot/clientshot-mockup03.svg'
import ClientshotMockup04 from '../assets/images/projects/clientshot/clientshot-mockup04.svg'

const SingleProject = ({ props }) => {

  React.useLayoutEffect(() => window.scrollTo(0, 0)); //scroll to top of page

  useEffect(() => {
    let first = true;
  
    console.log(props);
    return () => {
      first = false;
    }
  },)
  

  return (
    <>
      <div className='about-hero flex justify-center items-center text-center mt-24 mb-44'>
    <div className='w-2/4 flex flex-col justify-center items-center'>
    <img src={ClientshotLogo} alt="" draggable={false} />
            <div className='main-title'>
            #1 Customer Satisfaction Tool
            </div>
            <div className="sub-text mt-4  text-center">
            ClientShot is a customer satisfaction tool that helps businesses collect and analyze feedback from their customers. 
            </div>
            <div className="grid grid-cols-2 gap-2 mt-6">
              <div className="pill">Mobile App</div>
              <div className="pill">Web</div>
            </div>
    </div>

    </div>

    <div className="hero-image w-full grid grid-rows-1 justify-center items-center">
      <img src={ClientshotHero} alt="" />
    </div>

    <div className="project-details p-4 md:p-8">
      <div className="grid-cols-1 grid md:grid-cols-3">
        <div className="project-desc col-span-1">
          <div data-aos="fade-up" className='main-title'>
              Project <span className='alt-text'>details</span>
            </div> 

            <div className='details'>
                <div className='details-tab w-full py-6'>
                  <h6>Clients</h6>
                  <div className="grid gap-4 mt-4 grid-cols-2">
                      <img src={ihvn} alt="" />
                      <img src={valuejet} alt="" />
                  </div>
                </div>

                <div className='details-tab w-full py-6'>
                  <h6>Healthcare Pillar Addressed</h6>
                  <p>
                  Service delivery (coordination and efficiency), Information systems (data quality, analysis, dissemination, and use)
                  </p>
                </div>

                <div className='details-tab w-full py-6'>
                  <h6>Problem Solved</h6>
                  <p>
                  Lack of measuring customer satisfaction, customer feedback management and automating customer feedback
                  </p>
                </div>
            </div>
        </div>

        <div className='col-span-2'>
          <ServiceCard className=" h-full w-full"
            img={ClientshotMockup02}
            title={"Gather feedback"}
           />
        </div>

      </div>

      <div className="grid grid-cols-1 gap-4 mt-6 md:grid-cols-2">
      <ServiceCard className=" h-full w-full"
            img={ClientshotMockup03}
            title={"Mobile application"}
           />

        <ServiceCard className=" h-full w-full"
            img={ClientshotMockup04}
            title={"Gather feedback"}
           />
      </div>
    </div>
    </>
  )
}

export default SingleProject