import React from 'react'
import OurStoryTeam from '../assets/images/team/our-story-team.svg'
import ServiceCard from '../components/ServiceCard'
import LegacysystemIllustration from '../assets/svgs/illustration/Legacy+system.svg'
import Collaboration from '../assets/svgs/illustration/collaboration.svg'
import team01 from '../assets/images/team/01.png'
import team02 from '../assets/images/team/02.png'
import team03 from '../assets/images/team/03.png'
import team04 from '../assets/images/team/04.png'
import team05 from '../assets/images/team/05.png'
import team06 from '../assets/images/team/06.png'
import team07 from '../assets/images/team/07.png'
import team08 from '../assets/images/team/08.png'
import team09 from '../assets/images/team/09.png'
import team10 from '../assets/images/team/10.png'
import BlueTeam from '../assets/images/team/blue-team.svg'
import drTola from '../assets/images/team/portraits/dr+tola.jpg'
import Cutter from '../assets/svgs/cutter.svg'
import Lilian from '../assets/images/team/portraits/Lilian.png'
import Cornelius from '../assets/images/team/portraits/Cornelius.png'
import Modupe from '../assets/images/team/portraits/Modupe.png'
import Efe from '../assets/images/team/portraits/Efe.png'
import Triumph from '../assets/images/team/portraits/Triumph.png'
import Mustapha from '../assets/images/team/portraits/Mustapha.png'
import Tosin from '../assets/images/team/portraits/tosin.png'
import Doose from '../assets/images/team/portraits/Doose.png'
import Muze from '../assets/images/team/portraits/Muze.png'
import Ayomide from '../assets/images/team/portraits/Ayomide.png'
import Emmanuel from '../assets/images/team/portraits/Emmanuel.png'
import Marvellous from '../assets/images/team/portraits/Marvellous.png'
import Ayoola from '../assets/images/team/portraits/Ayoola.png'
import Lekan from '../assets/images/team/portraits/Lekan.png'

const About = () => {

    React.useLayoutEffect(() => window.scrollTo(0, 0)); //scroll to top of page
  return (
    <>
    <div className='about-hero flex justify-center items-center text-center  md:mt-24 mb-8 md:mb-44 p-4 md:p-0'>
    <div className='md:w-2/4 flex flex-col justify-center items-center'>
        <div data-aos="fade-up" className='pill'>
                About us
                </div>
            <div data-aos="fade-up" className='main-title'>
            Get to know <span className='alt-text'>who we are</span>
            </div>
            <div data-aos="fade-up" data-aos-delay="100" className="sub-text mt-4  text-center">
            SeamHealth Innovations is a leading multi-disciplinary healthcare consulting firm committed to delivering exceptional services and innovative technology solutions. 
        With a strong track record of success, we are dedicated to creating substantial value within the healthcare and business world.
            </div>
    </div>

    </div>

        <div data-aos="fade-up" className="team-with-cutter">
            <div >
                <img src={Cutter} className='cutter w-full' alt="" />
            </div>
            <div className="team-pictures grid grid-cols-4 gap-0 mb-21">
                <img  src={team01} alt="" draggable={false} />
                <img  src={team02} alt="" draggable={false} />
                <img  src={team03} alt="" draggable={false} />
                <img src={team04} alt="" draggable={false} />
                <img  src={team05} alt="" draggable={false} />
                <img  src={team06} alt="" draggable={false} />
                <img  src={team07} alt="" draggable={false} />
                <img  src={team08} alt="" draggable={false} />
                <img  src={team09} alt="" draggable={false} />
                <img  src={team04} alt="" draggable={false} />
                <img  src={team10} alt="" draggable={false} />
                <img src={team01} alt="" draggable={false} />
            </div>
        </div>

    <div data-aos="fade-up" className="figures p-4 md:p-8 py-20">
        <div className='grid md:grid-cols-2 justify-between items-center'>
            <div className='main-title text-center md:text-left md:w-3/4'>
                The <span className='alt-text'>figures</span> tell their own story
            </div>

            <div className='grid grid-cols-3 gap-12 justify-center mt-4 md:mt-0'>
                <div data-aos="fade" className="md:w-max text-center">
                    <div className="main-title">
                        <span className="alt-text">25+</span>
                    </div>
                    <div className="sub-text text-center">
                        Satisfied clients
                    </div>
                </div>

                <div data-aos="fade" className="md:w-max text-center">
                    <div className="main-title">
                        <span className="alt-text">7+</span>
                    </div>
                    <div className="sub-text text-center">
                        Years of experience
                    </div>
                </div>

                <div data-aos="fade" className="md:w-max text-center">
                    <div className="main-title">
                        <span className="alt-text">40+</span>
                    </div>
                    <div className="sub-text text-center">
                    Completed projects
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="grid md:grid-cols-2 p-4 md:p-8 md:mt-24 gap-8">
        <div>
            <div data-aos="fade-up" className='main-title  md:w-3/4'>
                Our <span className='alt-text'>story</span>
            </div>

            <div data-aos="fade-up" data-aos-delay="100" className="sub-text mt-4">
                    SeamHealth Innovations has been operating since 2016 and has grown to become a trusted partner for government, non-governmental, corporate, and private institutions in the healthcare sector. We take pride in our extensive industry knowledge and deep understanding of the unique challenges faced by healthcare providers.
            </div>

            <div data-aos="fade-up" data-aos-delay="200" className="sub-text mt-4">
            Our storySeamHealth Innovations has been operating since 2016 and has grown to become a trusted partner for government, non-governmental, corporate, and private institutions in the healthcare sector. We take pride in our extensive industry knowledge and deep understanding of the unique challenges faced by healthcare providers.
            </div>
        </div>
        
        <div data-aos="fade-up" data-aos-delay="100">
            <img src={OurStoryTeam} alt="" draggable={false} />
        </div>
    </div>

    <div className="grid grid-cols-1 md:grid-cols-2 md:p-8 p-4 gap-8">
        <div>
            <div data-aos="fade-up" className='main-title md:w-3/4'>
                <span className='alt-text'>Mission</span>
            </div>
            <div data-aos="fade-up" data-aos-delay="100" className="sub-text mt-4">
                Our mission is to empower healthcare organizations with innovative technology solutions and strategic consulting services to improve patient outcomes and operational efficiency.
            </div>
        </div>

        <div>
            <div data-aos="fade-up" className='main-title md:w-3/4'>
                <span className='alt-text'>Vision</span>
            </div>
            <div data-aos="fade-up" data-aos-delay="100" className="sub-text mt-4">
            We envision a healthcare landscape where every organization can harness the full potential of technology to deliver exceptional care and enhance overall wellness. 
            </div>
        </div>
    </div>

    <div className="grid-grid-cols-1 gap-8 md:mt-24 p-4 " >
        <div data-aos="fade" className="w-full grid grid-rows-1 items-center justify-center">
            <img src={BlueTeam} alt="" className='rounded-xl' />
            </div>
    </div>

            <div className="mt-20 grid grid-rows-1 gap-6 p-4 md:p-8 py-24" style={{background : '#F7FBFE'}}>
                  
            <div className=' w-full'>
                <div data-aos="fade-up" className='main-title'>
                Our core <span className='alt-text'>values</span> 
                </div>
                <div data-aos="fade-up" data-aos-delay="100" className="sub-text mt-4">
                    Our core values guide our decision-making, shape our culture, and ensure that we consistently deliver exceptional services that make a meaningful impact on our clients and the communities they serve.
                </div>
            </div>

                  <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-6">
                      <ServiceCard animation={"fade-up"} delay={""}
                        img={LegacysystemIllustration}
                        title={"Excellence"}
                        desc={"We are dedicated to excellence, continuously improving by setting high standards to surpass expectations in all our services and solutions."}
                      />

                        <ServiceCard animation={"fade-up"} delay={"200"}
                        img={Collaboration}
                        title={"Collaboration"}
                        desc={"We believe in the power of collaboration and teamwork. We work closely with our clients, listening to their needs and goals and leveraging our collective expertise to develop tailored solutions that drive sustainable success."}
                      />
                      
                        <ServiceCard animation={"fade-up"} delay={"100"}
                        img={LegacysystemIllustration}
                        title={"Integrity"}
                        desc={"We uphold the highest ethical standards in our interactions, fostering trust and transparency with clients, partners, and stakeholders."}
                      />
{/*                       
                        
                        <ServiceCard animation={"fade-up"} delay={""}
                        img={LegacysystemIllustration}
                        title={"Innovation"}
                        desc={"Innovation is central to our approach. We embrace emerging technologies, challenge conventional thinking, and find creative solutions to address evolving healthcare needs."}
                      />
                      
                        <ServiceCard animation={"fade-up"} delay={"100"}
                        img={LegacysystemIllustration}
                        title={"Client Focus"}
                        desc={"Innovation is central to our approach. We embrace emerging technologies, challenge conventional thinking, and find creative solutions to address evolving healthcare needs."}
                      />
                        <ServiceCard animation={"fade-up"} delay={"200"}
                        img={LegacysystemIllustration}
                        title={"Social Responsibility"}
                        desc={"Contributing to better healthcare, we advocate for solutions that improve outcomes, access, and health equity."}
                      /> */}
                  </div>
            </div>

            <div id='team' className="team p-4 md:p-8 grid grid-cols-1 md:grid-cols-2 mb-24 gap-8 h-3/4">
            <div className='p-4 md:p-8 w-full md:w-3/4'>
                <div data-aos="fade" className="pill">
                    Team
                </div>
                <div data-aos="fade-up" className='main-title'>
                The <span className='alt-text'>faces</span> behind our <span className='alt-text'>success</span>
                </div>
                <div data-aos="fade-up" data-aos-delay="100" className="sub-text mt-4">
                Meet the talents that drives our innovation
                </div>
                
              </div>

              <div className='team-members overflow-y-auto grid grid-cols-2 gap-8'>
                    <aside>
                        <div data-aos="zoom-in" className="member">
                            <img src={drTola} alt="" />
                            <span>
                                <h5>Adetola Olateju</h5>
                                <p>CEO & Managing Partner</p>
                            </span>
                        </div>
                        <div className="member">
                            <img src={Lilian} alt="" />
                            <span>
                                <h5>Lilian Ekpere</h5>
                                <p>Product Manager</p>
                            </span>
                        </div>
                        <div className="member">
                            <img src={Cornelius} alt="" />
                            <span>
                                <h5>Cornelius Ifoghale</h5>
                                <p>Senior Frontend Engineer</p>
                            </span>
                        </div>
                        <div className="member">
                            <img src={Modupe} alt="" />
                            <span>
                                <h5>Modupe Ade-Onojobi</h5>
                                <p>Backend Engineer</p>
                            </span>
                        </div>
                        <div className="member">
                            <img src={Triumph} alt="" />
                            <span>
                                <h5>Triumph Anazia</h5>
                                <p>Senior UI/UX Designer</p>
                            </span>
                        </div>
                        <div className="member">
                            <img src={Efe} alt="" />
                            <span>
                                <h5>Efe Osahon</h5>
                                <p>Frontend Engineer</p>
                            </span>
                        </div>
                        <div className="member">
                            <img src={Mustapha} alt="" />
                            <span>
                                <h5>Mustapha Adinoyi</h5>
                                <p>Product Analyst</p>
                            </span>
                        </div>
                        <div className="member">
                            <img src={Lekan} alt="" />
                            <span>
                                <h5>Lekan Adetunmbi</h5>
                                <p>Backend Engineer</p>
                            </span>
                        </div>
                    </aside>

                    <aside className=''>
                        <div className="member">
                            <img src={Tosin} alt="" />
                            <span>
                                <h5>Tosin Ajayi</h5>
                                <p>Project Manager</p>
                            </span>
                        </div>
                        <div className="member">
                            <img src={Doose} alt="" />
                            <span>
                                <h5>Doose Ayodeji</h5>
                                <p>Admin & Finance Coordinator</p>
                            </span>
                        </div>

                        <div className="member">
                            <img src={Muze} alt="" />
                            <span>
                                <h5>Muze Etoma</h5>
                                <p>Senior Backend Engineer</p>
                            </span>
                        </div>
                        <div className="member">
                            <img src={Ayomide} alt="" />
                            <span>
                                <h5>Ayomide Adigun</h5>
                                <p>Frontend Engineer</p>
                            </span>
                        </div>
                        <div className="member">
                            <img src={Emmanuel} alt="" />
                            <span>
                                <h5>Olaosebikan Emmanuel</h5>
                                <p>UI/UX Designer</p>
                            </span>
                        </div>
                        <div className="member">
                            <img src={Marvellous} alt="" />
                            <span>
                                <h5>Marvelous Ibgayo</h5>
                                <p>Business Developer</p>
                            </span>
                        </div>
                        <div className="member">
                            <img src={Ayoola} alt="" />
                            <span>
                                <h5>Tosin Ayoola</h5>
                                <p>Frontend Engineer</p>
                            </span>
                        </div>

                    </aside>
              </div>
            </div>
    </>
  )
}

export default About