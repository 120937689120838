import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import LegacyIcon from '../assets/svgs/legacy-icon.svg'
import CloudIcon from '../assets/svgs/cloud-icon.svg'
import Card from '../components/Card'
import Client1 from '../assets/images/client1.png'
import Client2 from '../assets/images/client2.png'
import Client3 from '../assets/images/client3.png'
import Client4 from '../assets/images/client4.png'
import Client5 from '../assets/images/client5.png'
import Client6 from '../assets/images/client6.png'
import ProjectCard from '../components/ProjectCard'
import ClientshotLogo from '../assets/svgs/logo/Clientshot_logo.svg'
import Smiletrainlogo from '../assets/images/projects/smiletrain/smiletrain-logo.svg'
import Smiletrainmockup from '../assets/images/projects/smiletrain/smiletrain-mockup.svg'
import Bridgelogo from '../assets/svgs/logo/bridge-logo.svg'
import BridgeMockup from '../assets/images/projects/elsrt/bridge/bridge-mockup.svg'
import ClientshotMockup from '../assets/images/clientshot+mockup.png'
import SoftwareDesignIcon from '../assets/svgs/software-design.svg'
import SoftwareOptimizationIcon from '../assets/svgs/software-optimization.svg'
import SoftwareOptimizationIconActive from '../assets/svgs/optimize-icon-active.svg'
import CapacityBuildingIcon from '../assets/svgs/capacity-building.svg'
import CapacityBuildingIconActive from '../assets/svgs/capacity-icon-active.svg'
import ClientshotPoster from '../assets/images/clientshot+poster.png'
import BackgroundVideo from '../assets/videos/AdobeStock_440434680_Video_4K_Preview.mov'
import SoftwareDesign from '../assets/videos/software-design.mp4'
import CapacityBuilding from '../assets/videos/capacity-building.mp4'
import SoftwareOptimization from '../assets/videos/software-optimization.mp4'

const Home = () => {

  const [serviceTab, setserviceTab] = useState(0);

  // React.useLayoutEffect(() => window.scrollTo(0, 0)); //scroll to top of page
  return (
    <>
          <section  className="section-one">
            <div className='hero-section'>
              {/* video */}
              {/* poster="https://assets.codepen.io/6093409/river.jpg" */}
              <video id="background-video" autoPlay loop muted >
                <source src={BackgroundVideo} type="video/mp4" />
              </video>
              {/* hero text */}
              <div className='p-4 md:p-8 hero-text text-center md:text-left w-full md:w-2/5'>
                <div data-aos="fade-up" className='main-title'>
                  Innovative <span className='alt-text'>Solutions</span> & Expert Consulting for Success
                </div> 
                <div data-aos="fade-up" data-aos-delay="200" className="sub-text mt-4">
                  Experience a transformative journey to success for your business with our cutting-edge software solutions, backed by the guidance of our seasoned consulting experts.
                </div>
                <form data-aos="fade-up" data-aos-delay="400" action="" className='flex flex-col items-center md:flex-row gap-2 mt-6'>
                  <input type="text" className="control-input w-full md:w-3/4" placeholder='Email address' required/>
                  <button className='btn btn-primary w-max' type={'submit'}>Schedule a call</button>
                </form>
              </div>
            </div>
          </section>

          <section className="section-two p-4 md:p-8 mt-48 flex flex-col justify-center items-center">

            <div className="flex flex-col md:flex-row w-full justify-between">

                <div className='flex flex-col md:items-start items-center justify-center md:w-2/5'>
                  <div data-aos="fade" className='pill'>
                    What we do
                  </div>

                  <div data-aos="fade-up" className='main-title'>
                  Enhance your existing workflow
                </div>
                </div>

                <div className="slider-one md:overflow-x-scroll grid grid-cols-1 gap-2 md:gap-6 mt-10 md:mt-0">

                  {/* card items */}
                  <Card img={LegacyIcon} 
                    title={'Legacy System Modernization'} 
                    body={"Upgrade and modernize your legacy systems to stay competitive in today's fast-paced technology landscape, improving efficiency and reducing costs."}
                  />

                  <Card img={CloudIcon} 
                    title={'Cloud-Based Solutions'} 
                    body={"Embrace the future of technology with scalable, secure, and cost-effective cloud-based solutions that streamline your operations and enhance productivity."}
                  />
                  
                  <Card img={CloudIcon} 
                    title={'Cloud-Based Solutions'} 
                    body={"Embrace the future of technology with scalable, secure, and cost-effective cloud-based solutions that streamline your operations and enhance productivity."}
                  />
                  <Card img={CloudIcon} 
                    title={'Cloud-Based Solutions'} 
                    body={"Embrace the future of technology with scalable, secure, and cost-effective cloud-based solutions that streamline your operations and enhance productivity."}
                  />
                
                </div>

            </div>



            {/* about seamhealth */}

              <div data-aos="zoom-in-up"  className='about-section p-6 flex flex-col md:flex-row gap-x-8 mt-52 items-center'>
                <video poster={ClientshotPoster} autoPlay loop muted  style={{height : 483, width : 483}}  >
                  <source src="https://www.youtube.com/watch?v=_QmyvFOasGk&pp=ygUKY2xpZW50c2hvdA%3D%3D" style={{height : 483, width : 'auto'}} type="video/mp4" />
                </video>
                  

                  <div>
                  <div data-aos="fade-up" data-aos-delay="100" className='main-title'>
                    About <span className='alt-text'>SeamHealth</span> Innovations
                  </div>

                  <div data-aos="fade-up" data-aos-delay="200" className="sub-text mt-4" style={{color : '#4E4E4E'}}>
                  SeamHealth Innovations is a leading multi-disciplinary healthcare consulting firm committed to delivering exceptional services and innovative technology solutions. 
                  </div>

                  <Link to='/about' data-aos="fade-up" data-aos-delay="300" className='btn btn-primary mt-4 w-max' >Read more</Link>
                </div>
              </div>

              
            

          </section>

          <section className="p-4 md:p-0 section-three mt-48 justify-center flex flex-col items-center">
            <div data-aos="fade-up" className='main-title text-center mb-16'>
                Our Services
              </div>
              <div data-aos="zoom-in-up" className='services-section flex flex-col-reverse md:flex-row p-4 md:p-6'>
                <div className="tabs md:w-3/5">
                  <button onClick={() =>setserviceTab(0)}   className={serviceTab === 0 ? "option active text-left" : "text-left option"}>
                    <img src={SoftwareDesignIcon} alt="" />
                      Software Design, Development and Deployment
                  </button>
                  <button onClick={() =>{setserviceTab(1)}}  data-aos-delay="100" className={serviceTab === 1 ? "option active mt-2 w-full" : "option mt-2 w-full"}>
                    {
                      serviceTab === 1 ? <img src={CapacityBuildingIconActive} alt="" />
                      : <img src={CapacityBuildingIcon} alt="" />
                    }
                    
                      Capacity Building 
                  </button>
                  <button onClick={() =>setserviceTab(2)} data-aos-delay="200" className={serviceTab === 2 ? "option active mt-2 w-full" : "option mt-2 w-full"}>
                    {
                      serviceTab === 2 ? <img src={SoftwareOptimizationIconActive} alt="" />
                      : <img src={SoftwareOptimizationIcon} alt="" />
                    }
                      Software Optimization
                  </button>
                </div>

                <div data-aos="zoom-out" data-aos-delay="200" className="tab-view flex flex-col md:flex-row gap-x-4 p-4">
                    

                    {serviceTab === 0 ? (
                      <div className='mb-4' style={{borderRadius : 10, overflow : 'hidden'}}>
                      <video poster={ClientshotPoster} autoPlay loop muted  style={{height : 'auto', width : 800}}  >
                          <source src={SoftwareDesign} style={{height : 483, width : 'auto'}} type="video/mp4" />
                    </video>
                    </div>
                     ) :serviceTab === 1 ? (
                       <div className='mb-4' style={{borderRadius : 10, overflow : 'hidden'}}>
                       <video poster={ClientshotPoster} autoPlay loop muted  style={{height : 'auto', width : 800}}  >
                       <source src={CapacityBuilding} style={{height : 483, width : 'auto'}} type="video/mp4" />
                     </video>
                     </div>
                     )
                     : (
                       <div className='mb-4' style={{borderRadius : 10, overflow : 'hidden'}}>
                       <video poster={ClientshotPoster} autoPlay loop muted  style={{height : 'auto', width : 800}}  >
                       <source src={SoftwareOptimization} style={{height : 483, width : 'auto'}} type="video/mp4" />
                     </video>
                     </div>
                    )}

                    <div className='md:w-3/5'>
                      {serviceTab === 0 ? (<img src={SoftwareDesignIcon} alt="" />)
                      : serviceTab === 1 ? (<img src={CapacityBuildingIconActive} alt="" />)
                      : (<img src={SoftwareOptimizationIconActive} alt="" />)
                      }
                      <h5 className="sub-title mt-4">
                      { serviceTab === 0 ? 'Software Design, Development, and Deployment'
                        : serviceTab === 1 ? 'Capacity Building'
                        : 'Software Optimization'
                    }
                      </h5>
                      <p className="sub-text mt-4">
                      { serviceTab === 0 ? 'We provide end-to-end support in designing, developing, and deploying software solutions for healthcare organizations.'
                        : serviceTab === 1 ? 'SeamHealth Innovations offers training programs to empower organizations, promoting continuous improvement and efficient resource allocation for sustainable growth.'
                        : 'We excel in configuring and adapting technology solutions to improve operational efficiency and enhance patient care. '
                    }
                      
                      </p>
                      <Link to="/services" className="btn btn-primary mt-8 w-max">Read more</Link>
                    </div>
                </div>
              </div>

              <div className='flex justify-center md:p-8'>
                <div data-aos="zoom-up" className="more-services flex flex-col md:flex-row justify-between items-center gap-6 md:gap-0 mt-12">
                    <div className='md:w-2/4'>
                        <h4 data-aos="fade-left" className='blue-heading text-center md:text-left'>Powerful Technology Solutions for Your Business</h4>
                        <p data-aos="fade-left" data-aos-delay="100" className='mt-2 text-center md:text-left' style={{color : '#808080'}}>
                        Discover innovation, efficiency, and growth through our top-tier services: Cloud Integration, Custom Software Development, and IT Consulting. Discover more ways we can elevate your business
                        </p>
                    </div>
                    <Link to={'/services'} data-aos="fade-right" data-aos-delay="200" className='btn btn-primary'>View all services</Link>
                </div>
              </div>
          </section>

          <section className='section-four mt-39 p-8'>
                <h4 data-aos="fade-up" className="gradient-heading text-center md:text-left">Clients we’ve worked with</h4>
                {/* <Carousel breakPoints={breakpoints}></Carousel> */}
                <marquee behavior="" direction="">
                  <div data-aos="zoom-out" className='client-list flex gap-x-20 mt-6 '>
                    <img src={Client1} alt="" draggable={false} />
                    <img src={Client2} alt="" draggable={false} />
                    <img src={Client3} alt="" draggable={false} />
                    <img src={Client4} alt="" draggable={false} />
                    <img src={Client5} alt="" draggable={false} />
                    <img src={Client6} alt="" draggable={false} />
                  </div>
                </marquee>
          </section>

          <section className='section-five mt-39 px-4 md:px-8 py-16' style={{marginTop : 156}}>
              <div className="flex flex-col md:flex-row md:justify-between  mb-12">
                <div className='flex flex-col items-center md:items-start'>
                    <div data-aos="fade-left" className='pill'>
                      Portfolio
                    </div>
                    <div data-aos="fade-left" data-aos-delay="100" className='gradient-heading'>
                    Recent Projects
                  </div>
                </div>
                <Link to="/projects" data-aos="fade-right" data-aos-delay="200" className="btn btn-primary hidden md:block"
                
                >See all projects</Link>
              </div>

              {/* <div className="flex flex-col md:flex-row justify-content mt-6 gap-6"> */}
              <div className="grid grid-cols-1 md:grid-cols-2 justify-content mt-6 gap-6">

              <div data-aos="zoom-in-up" className="project-card md:col-span-2 p-4">
                  <Link to={"/projects/clientshot"}>
                    <div className='flex flex-col md:flex-row'>
                      <div className='md:w-2/5'>
                        <img className='logo' src={ClientshotLogo} alt="" draggable={false} />
                        <p>
                          ClientShot is a customer satisfaction tool that helps businesses collect and analyze feedback from their customers.
                          <br /> <br />
                          With ClientShot, companies can collect feedback through various channels, including USSD, Web, and WhatsApp.
                        </p>
                        <div className="tags mt-6 flex gap-x-2">
                          <div className='pill'>
                            Mobile App
                          </div>
                          <div className='pill'>
                            Web
                          </div>
                        </div>
                        {/* <img src={Ripple} alt="" className='ripple' /> */}
                      </div>
                      <div>
                        <img src={ClientshotMockup} alt="" draggable={false} />
                      </div>
                    </div>
                  </Link>
                </div>

              <div data-aos="zoom-in-up" className='w-full' style={{borderRadius : 20}}>
                <ProjectCard
                link={"/projects/smiletrain"}
                logo={Smiletrainlogo}
                mockup={Smiletrainmockup}
                desc="Smile Train is the largest cleft-focused organization with a model of true sustainability — providing training, funding, and resources to empower local medical professionals in 70+ countries to provide 100%-free cleft surgery and other forms of essential cleft 
                care in their own communities."
              />
                </div>

                <div data-aos="zoom-in-up" className='w-full' style={{backgroundColor : 'white', borderRadius : 20}}>
                <ProjectCard
                link={"/projects/bridge"}
                  logo={Bridgelogo}
                  mockup={BridgeMockup}
                  desc="The BRIDGE app is a simple, Android-based m-Health application designed for use by trained community health workers in rural settings to improve
                  early detection and reduce delays to the diagnosis of breast cancer in Nigeria."
                />
                </div>
              </div>
          </section>

    </>
  )
}

export default Home