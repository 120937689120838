import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import SeamhealthLogo from '../assets/svgs/logo/logo.svg'
import Facebook from '../assets/svgs/socials/facebook.svg'
import Twitter from '../assets/svgs/socials/twitter.svg'
import Linkedin from '../assets/svgs/socials/linkedin.svg'
import Instagram from '../assets/svgs/socials/instagram.svg'


const Footer = () => {

  const date = new Date()
  const [copydate] = useState(date.getFullYear())

  return (
    <>
        <div className="flex justify-center p-4 md:p-8 mt-24">
          <div data-aos="zoom-out-up" className='jumbotron md:px-36 p-4 py-12 md:py-0 flex items-center text-center justify-center flex-col'>
            {/* Jumbotron */}

            {/* <div className="jumbotron-inner w-full"> */}
              <h4 data-aos="fade-up">
              Transform Your Business with Our Software Solutions
              </h4>
              <p data-aos="fade-up" className="text-center mt-2">
              Experience a new level of efficiency and growth with our custom software solutions. Stay ahead of the competition and harness technology to your advantage
              </p>
              <Link to="/contact" data-aos="fade-up" className="mt-6 btn btn-primary">
                Schedule a call
              </Link>
            {/* </div> */}
          </div>
        </div>

        <footer className='mt-16 p-8 flex flex-col gap-12 md:gap-0 md:flex-row justify-between'>
            <div className='md:w-1/5'>
                <Link to={'/'}><img src={SeamhealthLogo} alt="" /></Link>
                <div className="about-desc mt-3">
                SeamHealth Innovations Inc. is a software solutions company with the mandate to deliver high quality and reliable technology solutions to medium AND large scale  institutions in all sectors .
                </div>
                <Link to={"/about"} className='btn-link' >Read more</Link>
            </div>

            <div className='flex flex-col md:flex-row gap-12 md:gap-32 w-2/5'>
              <div className='md:w-1/5'>
                  <h6>Company</h6>
                  <ul className='mt-6'>
                    <Link to="/about">
                      <li className='nav-link'>About us</li>
                    </Link>
                    <a href={"/about#team"}>
                      <li className='nav-link mt-2'>Meet the team</li>
                    </a>
                    <Link to={"/services"}>
                      <li className='nav-link mt-2'>Services</li>
                    </Link>
                    <Link to={"/projects"}>
                      <li className='nav-link mt-2'>Projects</li>
                    </Link>
                  </ul>
              </div>
              <div className='md:w-1/5'>
                  <h6>Get in touch</h6>
                  <ul className='flex gap-x-4 mt-6'>
                    <li className='nav-link'><img style={{height : 24, width : 24}} src={Linkedin} alt="" /></li>
                    <li className='nav-link'><img style={{height : 24, width : 24}} src={Instagram} alt="" /></li>
                    <li className='nav-link'><img style={{height : 24, width : 24}} src={Twitter} alt="" /></li>
                    <li className='nav-link'><img style={{height : 24, width : 24}} src={Facebook} alt="" /></li>
                  </ul>
              </div>
            </div>
        </footer>

        <div className='mt-21 flex flex-col md:flex-row justify-between items-center p-8' style={{color : '#808080'}}>
          <div style={{fontSize : 14}}>(c) 2016 - {copydate} | SeamHealth Innovations</div>

          <div className='flex gap-x-6'>
          <ul className='flex gap-x-4 mt-6'>
                <Link to={"/"}>
                  <li className="nav-link">Privacy Policy</li>
                </Link>
                <Link to={"/"}>
                  <li className="nav-link">Terms of Use</li>
                </Link>
                <Link to={"/"}>
                  <li className="nav-link">Sitemap</li>
                </Link>
              </ul>
          </div>
        </div>
    </>
  )
}

export default Footer