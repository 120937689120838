import React, { useState } from 'react'
// import '../assets/css/output.css' //tailwind + custom css 
import { Link } from 'react-router-dom'
import logo from '../assets/svgs/logo/logo.svg'
import MenuIcon from '../assets/svgs/menu.svg'

const Header = () => {

    const [linkUrl, setLinkUrl] = React.useState(window.location.pathname);
    const [nav, setNav] = React.useState(0)
    const [menu, toggleMenu] = useState(false)
    

  return (
    <>
        <nav className='flex navigation md:static justify-between items-center w-full p-4 md:p-8'>
            <div className="logo"><Link to={'/'}><img src={logo} alt="" /></Link></div>
            <div className='nav-links hidden md:flex'>
                <ul className='flex'>
                    <Link to={'/'}>
                        <li className={nav === 0 && linkUrl === '/' ? 'active' : ''}
                            onClick={() => {setNav(0); setLinkUrl('/')}}
                        >Home</li>
                    </Link>
                    <Link to={'/about'}>
                        <li className={nav === 1 || linkUrl === '/about' ? 'active' : ''} 
                        onClick={() => {setNav(1); setLinkUrl('/about')}}
                        >About</li>
                    </Link>
                    <Link to={'/services'}>
                        <li className={nav === 2 || linkUrl === '/services' ? 'active' : ''}
                        onClick={() => {setNav(2); setLinkUrl('/services')}}
                        >Services</li>
                    </Link>
                    <Link to={'/projects'}>
                        <li className={nav === 3 || linkUrl === '/projects' ? 'active' : ''}
                        onClick={() => {setNav(3); setLinkUrl('/projects')}}
                        >Projects</li>
                    </Link>
                </ul>
            </div>
            <div className='cta hidden md:block'>
                <Link to='/contact' className='btn btn-primary'>Speak to us</Link>
            </div>

            <div className="menu block md:hidden">
                <button className='btn btn-secondary' onClick={() => toggleMenu(!menu)}>
                    <img src={MenuIcon} alt="" />
                </button>
            </div>
        </nav>

        {menu && (<div className="overlay">
            <div className="mobile-nav p-4">
                <div data-aos="fade-up" className="pane">
                    <ul className='grid grid-rows-1 gap-2'>
                        {/* <li className="menu-item"></li> */}
                        <Link to={'/'}>
                            <li className={nav === 0 && linkUrl === '/' ? 'active' : ''}
                                onClick={() => {setNav(0); setLinkUrl('/'); toggleMenu(false)}}
                            >Home</li>
                        </Link>
                        <Link to={'/about'}>
                        <li className={nav === 1 || linkUrl === '/about' ? 'active' : ''} 
                        onClick={() => {setNav(1); setLinkUrl('/about'); toggleMenu(false)}}
                        >About</li>
                    </Link>
                    <Link to={'/services'}>
                        <li className={nav === 2 || linkUrl === '/services' ? 'active' : ''}
                        onClick={() => {setNav(2); setLinkUrl('/services'); toggleMenu(false)}}
                        >Services</li>
                    </Link>
                    <Link to={'/projects'}>
                        <li className={nav === 3 || linkUrl === '/projects' ? 'active' : ''}
                        onClick={() => {setNav(3); setLinkUrl('/projects'); toggleMenu(false)}}
                        >Projects</li>
                    </Link>

                        <div className='cta md:hidden'>
                            <Link to='/contact' onClick={() => toggleMenu(false)} className='btn btn-primary'>Speak to us</Link>
                        </div>
                    </ul>
                </div>
            </div>
        </div>)}

        {/* <div className="mb-24"></div> */}
    </>
  )
}

export default Header