import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/style.css'
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from './App';
import Layout from './layouts/Layout'
import Home from './pages/Home';
import Contact from './pages/Contact';
import Services from './pages/Services';
import Project from './pages/Project';
import About from './pages/About';
import SingleProject from './pages/SingleProject';
import SingleProjectEkiti from './pages/SingleProjectEkiti';
import SingleProjectKncv from './pages/SingleProjectKncv';
import SingleProjectBridge from './pages/SingleProjectSmiletrainBridge';
import SingleProjectElsrt from './pages/SingleProjectSmiletrainElsrt';
import SingleProjectSmiletrain from './pages/SingleProjectSmiletrain';
import AOS from 'aos';
import 'aos/dist/aos.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

AOS.init();

const router = createBrowserRouter([
  {
    element: <Layout />,
    // errorElement: <Error />,

    children : [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/contact",
        element: <Contact />
      },
      {
        path: "/services",
        element: <Services />
      },
      {
        path: "/projects",
        element: <Project />
      },
      {
        path: "/projects/:id",
        element: <SingleProject />
      },
      {
        path: "/projects/elsrt",
        element: <SingleProjectElsrt />
      },
      {
        path: "/projects/smiletrain",
        element: <SingleProjectSmiletrain />
      },
      {
        path: "/projects/bridge",
        element: <SingleProjectBridge />
      },
      {
        path: "/projects/ekiti",
        element: <SingleProjectEkiti />
      },
      {
        path: "/projects/kncv",
        element: <SingleProjectKncv />
      },
      {
        path: "/about",
        element: <About />
      },
    ],
    // errorElement: <Error />
  }
]);


root.render(<RouterProvider router={router} />
  // <React.StrictMode>
  //   {/* <App /> */}
  //   <Home />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
