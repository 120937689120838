import React from 'react'
import { useForm, ValidationError } from '@formspree/react';
import MapBase from '../assets/svgs/map-base.svg'
import EmailIcon from '../assets/svgs/mail.svg'
import PhoneIcon from '../assets/svgs/phone.svg'
import AddressIcon from '../assets/svgs/map-pin.svg'

function Contact() {
    React.useLayoutEffect(() => window.scrollTo(0, 0)); //scroll to top of page

    
// function ContactForm() {
  const [state, handleSubmit] = useForm("myyrwoey");
  if (state.succeeded) {
      return <p>Your message was sent successfully</p>;
  }
    // const sendEmail = async (e) => {
    //     e.preventDefault();

    //     try{
    //         setLoading(true); //run loader
    //         await emailjs.sendForm('service_vtqzlmi', 'template_co9u72s', form.current, 'u0cbkTB5JQL9KJpYx')
    //         setSuccess(true);
    //         setLoading(false); //stop loader
    //         setTimeout(() => setSuccess(false), 3000);
    //     }catch(e){
    //         setLoading(false)
    //         setError(true);
    //         setTimeout(() => setError(false), 3000);
    //     }
    // };



  return (
    <div>
        <div className="contact-hero flex flex-col items-center justify-center p-4 md:p-8">
            <div className='p-4 md:p-8 hero-inner text-center flex flex-col justify-center items-center'>
                    <div data-aos="fade-up"  className='pill'>
                      Get in touch
                    </div>
                    <div data-aos="fade-up" data-aos-delay="100" className='main-title'>
                      We would be <span className='alt-text'>delighted</span> to hear from you
                    </div>
                    <div data-aos="fade-up" data-aos-delay="200" className="sub-text mt-4">
                    If you require help or have inquiries, kindly complete the form below, and we will respond at our earliest convenience.
                    </div>
                    
                </div>
                <div data-aos="fade" data-aos-delay="200" ><img src={MapBase}  alt="" draggable={false} /></div>
        </div>

        <section className='contact-info text-center flex flex-col items-center justify-center md:px-28 p-4 md:p-8'>
            <div data-aos="fade-up" className='main-title'>
                Send us a message
            </div>

            <form className='w-full' method='POST' onSubmit={handleSubmit}>
                <div data-aos="fade-up" className="contact-form p-4 md:py-8 md:px-6 w-full mt-4" >
                    <div className="input-group w-full">
                        <input data-aos="fade-up" className='control-input w-full' type="text" name="" id="" placeholder='Name'/>
                        <input data-aos="fade-up" data-aos-delay="100" className='control-input' type="text" name="" id="" placeholder='Phone number'/>
                    </div>
                    <div data-aos="fade-up" className="input-group w-full mt-4">
                        <input type="email" className="control-input" placeholder='Email address' />
                    </div>
                    <div data-aos="fade-up" className="input-group w-full mt-4">
                        {/* <input type="email" className="control-input" placeholder='Email address' /> */}
                        <textarea className='control-input w-full' name="" id="" cols="30" rows="10" placeholder='Write your message'></textarea>
                    </div>
                    <div data-aos="fade-up" className='mt-6'>
                        <button type='submit' disabled={state.submitting} className='btn btn-primary w-full'>Send message</button>
                    </div>
                </div>
            </form>

            <div data-aos="fade-up" className="other-contact mt-24 w-full mb-24">
                <h5 className='text-left'>
                Another option is to contact us through the provided email address and phone number:
                </h5>

                <div className='mt-6 flex flex-col justify-center gap-y-6'>
                    <div className='flex gap-x-2'>
                        <div className="icon-style"><img src={EmailIcon} alt="" /></div>
                        <div className='text-left'>
                            <div style={{color : '#A4A4A4'}}>Email</div>
                            <div>info@seamhealthgroup.com</div>
                        </div>
                    </div>

                    <div className='flex gap-x-2'>
                        <div className="icon-style"><img src={PhoneIcon} alt="" draggable={false} /></div>
                        <div className='text-left'>
                            <div style={{color : '#A4A4A4'}}>Phone</div>
                            <div>+2349151104924</div>
                        </div>
                    </div>

                    <div className='flex gap-x-2'>
                        <div className="icon-style"><img src={AddressIcon} alt="" /></div>
                        <div className='text-left'>
                            <div style={{color : '#A4A4A4'}}>Office address</div>
                            <div className=' md:w-2/4'>International Research Center of Excellence Towers. 6, Emeritus Umar Shehu Ave. (5th Floor, Tower A), Institutions and Research District, Abuja, Nigeria.</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Contact