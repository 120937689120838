import React from 'react'

const Card = ({ img, title, body }) => {
  return (
    <>
        <div data-aos="fade-up" className="card-item">
            <div className="card-icon">
                <img src={img} alt="" />
            </div>
            <p className='card-title md:mb-4'>{title}</p>
            <p className='card-body'>
                {body}
            </p>
        </div>
    </>
  )
}

export default Card