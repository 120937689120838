import React from 'react'

const ServiceCard = ({ img, title, desc, video, animation, delay }) => {
  return (
    <>
        <div data-aos={animation} data-aos-delay={delay} className='service-card' style={{backgroundColor : '#ffffff'}}>
            <div className="flex justify-center w-full rounded-lg overflow-hidden">
                {img && <img src={img} alt="" />}
                {video && <video id="background-video" autoPlay loop muted >
                <source src={video} type="video/mp4" />
              </video>}
                </div>
            <div className="service-card-body">
                <h4>{title}</h4>
                <p className='mt-2 md:mt-4'>
                {desc}
                </p>
            </div>
        </div>
    </>
  )
}

export default ServiceCard