import React from 'react'
import { Link } from 'react-router-dom'

const ProjectCard = ({ logo, mockup, desc, web = "", mobile = "", link }) => {
  return (
    <>
        <Link to={link}>
          <div data-aos="fade-up" className="project-card">
                    <div className='flex flex-col'>
                      <div className=''>
                        <img src={logo} className='logo mb-2' alt="" draggable={false} />
                        <p>
                        {desc}
                        </p>
                        {web =="" && mobile == "" && (<div className="tags mt-6 flex gap-x-2">
                          <div className='pill'>
                            Mobile App
                          </div>
                          <div className='pill'>
                            Web
                          </div>
                        </div>)}
                        { mobile != "" && (
                          <div className="tags mt-6 flex gap-x-2">
                            <div className='pill'>
                              Mobile App
                            </div>
                          </div>
                        )}
                      { web != "" && (
                          <div className="tags mt-6 flex gap-x-2">
                            <div className='pill'>
                              Web
                            </div>
                          </div>
                        )}
                        {/* <img src={Ripple} alt="" className='ripple' /> */}
                      </div>
                      <div className='flex justify-end'>
                        <img src={mockup} className='mockup' alt="" draggable={false} />
                      </div>
                    </div>
                    </div>
        </Link>
    </>
  )
}

export default ProjectCard