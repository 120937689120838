import React from 'react'
import { Link } from 'react-router-dom'
import Bridgelogo from '../assets/svgs/logo/bridge-logo.svg'
import ClientshotMockup from '../assets/images/clientshot+mockup.png'
import ClientshotLogo from '../assets/svgs/logo/Clientshot_logo.svg'
import Elsrt001 from '../assets/images/projects/elsrt/elsr001.svg'
import ElsrtLogo from '../assets/images/projects/elsrt/elsrt-logo.svg'
import BridgeMockup from '../assets/images/projects/elsrt/bridge/bridge-mockup.svg'
import ProjectCard from '../components/ProjectCard'
import Smiletrainlogo from '../assets/images/projects/smiletrain/smiletrain-logo.svg'
import Smiletrainmockup from '../assets/images/projects/smiletrain/smiletrain-mockup.svg'
import Ekitilogo from '../assets/images/projects/ekiti/ekiti-logo.svg'
import Ekitimockup from '../assets/images/projects/ekiti/ekiti-mockup.svg'
import Kncvlogo from '../assets/images/projects/kncv/kncv-logo.svg'
import Kncvmockup from '../assets/images/projects/kncv/kncv-mockup.svg'

const Project = () => {
  React.useLayoutEffect(() => window.scrollTo(0, 0)); //scroll to top of page
  return (
    <>
        <div className='project-hero grid grid-cols-1 md:grid-cols-2 items-end gap-6 p-4 md:p-8'>
            <div className='p-4 md:p-8 grid grid-cols-1 justify-self-center md:justify-self-start justify-items-center md:justify-items-start  '>
                <div data-aos="fade" className='pill '>
                    Portfolio
                  </div>
                <div data-aos="fade-up" className='main-title'>
                    Recent Projects
                </div>
            </div>

            <ProjectCard
            link={"/projects/elsrt"}
              logo={ElsrtLogo}
              mockup={Elsrt001}
              desc="E-lab Sample Referral Tracking Software (ELSRT) is a software-based solution with features that support a modern laboratory’s operations."
            />

              <ProjectCard
              link={"/projects/bridge"}
              logo={Bridgelogo}
              mockup={BridgeMockup}
              desc="The BRIDGE app is a simple, Android-based m-Health application designed for use by trained community health workers in rural settings to improve
              early detection and reduce delays to the diagnosis of breast cancer in Nigeria."
            />

            <ProjectCard
            link={"/projects/smiletrain"}
              logo={Smiletrainlogo}
              mockup={Smiletrainmockup}
              desc="Smile Train is the largest cleft-focused organization with a model of true sustainability — providing training, funding, and resources to empower local medical professionals in 70+ countries to provide 100%-free cleft surgery and other forms of essential cleft 
              care in their own communities."
            />

              <ProjectCard
              link={"/projects/ekiti"}
              logo={Ekitilogo}
              mockup={Ekitimockup}
              web='web'
              desc="The ekiti health is a two-version web application that  consists of PVT (post vaccination tracking tool) and LIMS (Laboratory information management system)"
            />

              <ProjectCard
              link={"/projects/kncv"}
              logo={Kncvlogo}
              mockup={Kncvmockup}
              web='web'
              desc="Keep track of patients' USSD records, monitor the follow up of patients with tuberculosis and visualize key statistical insights."
            />




        </div>

        <div className="p-4 md:p-8 mb-28">
            <Link to={'/projects/clientshot'}>
              <div className="project-card p-4 ">
                        <div className='flex flex-col md:flex-row'>
                          <div className=''>
                            <img src={ClientshotLogo} className='logo' alt="" draggable={false} />
                            <p>
                              ClientShot is a customer satisfaction tool that helps businesses collect and analyze feedback from their customers.
                              <br /> <br />
                              With ClientShot, companies can collect feedback through various channels, including USSD, Web, and WhatsApp.
                            </p>
                            <div className="tags mt-6 flex gap-x-2">
                              <div className='pill'>
                                Mobile App
                              </div>
                              <div className='pill'>
                                Web
                              </div>
                            </div>
                            {/* <img src={Ripple} alt="" className='ripple' /> */}
                          </div>
                          <div>
                            <img src={ClientshotMockup} alt="" draggable={false} />
                          </div>
                        </div>
                        {/* <img src={Ripple} alt="" className='ripple' /> */}
                      </div>
            </Link>
        </div>
    </>
  )
}

export default Project