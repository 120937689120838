import React from 'react'
import { Link } from 'react-router-dom'
import Smiletrainmockup from '../assets/images/projects/smiletrain/smiletrain-mockup.svg'
import ProjectCard from '../components/ProjectCard'
import BridgeMockup from '../assets/images/projects/elsrt/bridge/bridge-mockup.svg'
import SoftwareDevIllustration from '../assets/svgs/illustration/custom+software+dev.svg'
import DataAnalyticsIllustration from '../assets/svgs/illustration/Data+Analytics.svg'
import DevopsIllustration from '../assets/svgs/illustration/devops.svg'
import LegacysystemIllustration from '../assets/svgs/illustration/Legacy+system.svg'
import CloudsystemIllustration from '../assets/svgs/illustration/cloud+system.svg'
import EHRdeploymentIllustration from '../assets/svgs/illustration/EHR+Deployment.svg'
import SoftwareDesign from '../assets/videos/software-design.mp4'
import CapacityBuilding from '../assets/videos/capacity-building.mp4'
import SoftwareOptimization from '../assets/videos/software-optimization.mp4'
import iot from '../assets/videos/iot.mp4'
import Smiletrainlogo from '../assets/svgs/logo/smiletrain-logo.svg'
import Bridgelogo from '../assets/svgs/logo/bridge-logo.svg'
import ServiceCard from '../components/ServiceCard'


const Services = () => {
  React.useLayoutEffect(() => window.scrollTo(0, 0)); //scroll to top of page
  return (
    <>
        <div className='services-hero'>
              
              {/* hero text */}
              <div className='p-4 md:p-8 w-full md:w-3/5'>
                <div data-aos="fade-up" className='main-title'>
                Comprehensive Range of Services  <span className='alt-text'>Tailored</span> to support Healthcare Organizations
                </div>
                <div data-aos="fade-up" data-aos-delay="100" className="sub-text mt-4">
                    Our services are characterized by a client-centric approach, where we actively listen to our client's needs, collaborate closely with their teams, and tailor our solutions accordingly. 
                </div>
                
              </div>
          </div>

          <div className="features grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-6 w-full p-4 md:p-8">
                  <ServiceCard animation={"fade-up"} delay={"200"}
                  img={SoftwareDevIllustration}
                  title={"Custom software development"}
                  desc={"Unlock the full potential of your organization with tailored software solutions built to meet your unique needs and drive efficiency."}
                  />

                  <ServiceCard animation={"fade-up"} delay={"300"}
                  img={DataAnalyticsIllustration}
                  title={"Data Analytics and Business Intelligence"}
                  desc={"Make informed decisions with our data analytics and business intelligence solutions, providing actionable insights to fuel your organization’s growth."}
                  />

                  <ServiceCard animation={"fade-up"} delay={"400"}
                  img={DevopsIllustration}
                  title={"DevOps and Continuous Integration/Continuous Deployment (CI/CD)"}
                  desc={" Enhance your development pipeline with our DevOps and CI/CD services, ensuring rapid and reliable software delivery for your organization's success."}
                  />

                  <ServiceCard animation={"fade-up"}
                  img={LegacysystemIllustration}
                  title={"Legacy System Modernization"}
                  desc={"Upgrade and modernize your legacy systems to stay competitive in today's fast-paced technology landscape, improving efficiency and reducing costs."}
                  />

                  <ServiceCard animation={"fade-up"} delay={"100"}
                  img={CloudsystemIllustration}
                  title={"Cloud-Based Solutions"}
                  desc={"Embrace the future of technology with scalable, secure, and cost-effective cloud-based solutions that streamline your operations and enhance productivity."}
                  />

                  <ServiceCard animation={"fade-up"} delay={"200"}
                  img={EHRdeploymentIllustration}
                  title={"EHR Deployment"}
                  desc={"SeamHealth provides expert services in EHR Deployment and sustainability of projects"}
                  />
          </div>

          <div className="mt-40 p-4 md:p-8 py-28" style={{backgroundColor : '#F7FBFE'}}>
            <div className=' md:w-3/5'>
                <div data-aos="fade-up" className='main-title'>
                We offer Integrated IT Solutions for <span className='alt-text'>Seamless</span>  Healthcare Management  
                </div>
                <div data-aos="fade-up" data-aos-delay="100" className="sub-text mt-4">
                Empowering healthcare institutions with cutting-edge technology to streamline operations, enhance patient care, and foster a connected ecosystem for efficient healthcare management.
                </div>
                
              </div>

              <div className="mt-20 grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-6">
                  {/* <div className="service-card-two">
                    <img src="" alt="" />
                  </div> */}

                  <ServiceCard animation={"fade-up"} 
                    video={SoftwareDesign}
                    title={"Software Design, Development and Deployment"}
                    desc={"We provide end-to-end support in designing, developing, and deploying software solutions for healthcare organizations."}
                  />

                  <ServiceCard animation={"fade-up"} delay={"100"}
                    video={CapacityBuilding}
                    title={"Software Design, Development and Deployment"}
                    desc={"We provide end-to-end support in designing, developing, and deploying software solutions for healthcare organizations."}
                  />

                  <ServiceCard animation={"fade-up"}
                    video={SoftwareOptimization}
                    title={"Software Design, Development and Deployment"}
                    desc={"We provide end-to-end support in designing, developing, and deploying software solutions for healthcare organizations."}
                  />

                  <ServiceCard animation={"fade-up"} delay={"100"}
                    video={iot}
                    title={"Software Design, Development and Deployment"}
                    desc={"We provide end-to-end support in designing, developing, and deploying software solutions for healthcare organizations."}
                  />
              </div>
          </div>

          <div className="my-24 p-8">
          <div className="grid grid-cols-1 md:grid-cols-2 md:justify-between items-start">
            <div className=' md:w-4/5'>
                  <div data-aos="fade-left" className='main-title'>
                  Past <span className='alt-text'>Successes</span> with Top Organizations
                  </div>
                  <div data-aos="fade-left" data-aos-delay="100" className="sub-text mt-4">
                    Explore our portfolio of successful projects completed for reputable organizations.
                  </div>
            
                </div>
                <div className='md:justify-self-end hidden md:block'>
                <Link to="/projects" data-aos="fade-right" data-aos-delay="200" className="btn btn-primary w-max"
                
                >See all projects</Link>
                </div>
          </div>


          <div className="grid grid-cols-1 md:grid-cols-2 justify-content mt-6 gap-6">
          <div data-aos="zoom-in-up" className='w-full' style={{borderRadius : 20}}>
          <ProjectCard
            link={"/projects/smiletrain"}
              logo={Smiletrainlogo}
              mockup={Smiletrainmockup}
              desc="Smile Train is the largest cleft-focused organization with a model of true sustainability — providing training, funding, and resources to empower local medical professionals in 70+ countries to provide 100%-free cleft surgery and other forms of essential cleft 
              care in their own communities."
            /> </div>

                <div data-aos="zoom-in-up" data-aos-delay="100" className='w-full' style={{backgroundColor : 'white', borderRadius : 20}}>
                <ProjectCard
                link={"/projects/bridge"}
                logo={Bridgelogo}
                mockup={BridgeMockup}
                desc="The BRIDGE app is a simple, Android-based m-Health application designed for use by trained community health workers in rural settings to improve
                early detection and reduce delays to the diagnosis of breast cancer in Nigeria."
              />
                </div>
              </div>
          </div>
    </>
  )
}

export default Services